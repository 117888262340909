/**
 * <PRE>
 * System Name : 사랑온 NEXT 시스템(TSS2-MSP)
 * Business Name : 청약
 * Class Name : PSSha256.js
 * Description :
 *              AS-IS 파일 SHA256.js 대응
 *              SHA256 암호화 유틸
 * </PRE>
 */
 
const crypto = require('crypto')
const PSSha256 = {}
/************************************************************************************************
 * Function명  : computeDigest
 * 설명        : SHA256 암호화
 * @param data : 암호화 대상 값
 ************************************************************************************************/
 PSSha256.computeDigest = function (data) {
  return crypto.createHash('sha256').update(data).digest('hex')
}
export default PSSha256
// eslint-disable-next-line

